import ControlRoomTicketTags from "./ControlRoomTicketTags";
import TicketTable from "./TicketTable";
import './ControlRoomTickets.scss'
import Plus from '../../../../../images/plus.svg'
import ChevronDown from '../../../../../images/chevron-down.svg'
import ChevronUp from '../../../../../images/chevron-up.svg'
import SearchIcon from '../../../../../images/search-md.svg'
import { useEffect, useMemo, useRef, useState } from "react";
import ControlRoomTicketFilter from "./controlRoomTicketFilter/ControlRoomTicketFilter";
import ReassignTicketModal from "./reassignTicketModal/ReassignTicketModal";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import { bulkCloseTicket, bulkDownloadTicket, getTicketTable } from "../slices/ticketSlice";
import { TicketUser, Operators, Status, TicketAction } from "../interfaces/ticketInterface";
import TicketModelOpen from "../TicketModelOpen";
import { showToaster } from "../../../../dashboard/components/asset-location/AssetList";

export interface SelectedTicket {
    ticketId: number,
    status: string
}
const ControlRoomTickets = () => {
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [isBulkActionOpened, setIsBulkActionOpened] = useState(false);
    const [selectedTickets, setSelectedTickets] = useState<SelectedTicket[]>([]);
    const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
    const [activeTicketTagIndex, setActiveTicketTagIndex] = useState(0);
    const [activeTicketTag, setActiveTicketTag] = useState('');
    const bulkActionDropDownRef = useRef<HTMLDivElement>(null);
    const searchValue = useMemo(() => searchTerm, [searchTerm]);
    const { ticketTagsData, ticketTableLoading, operatorsAPIresponse } = useAppSelector((state) => state.ticketTableSlice);
    const [selectedFilter, setSelectedFilter] = useState<{ [key: string]: (string | Operators | TicketUser | Status)[] }>({});
    const abortController = useRef<AbortController | null>(null);
    const [loading, setLoading] = useState(true);
    const [isTicketsModalOpen, setIsTicketsModalOpen] = useState(false);
    const pageSize = 25;
    const defaultPageNumber = 1;
    // Transform selectedFilter to only contain strings
    const transformSelectedFilter = (filter: { [key: string]: (string | Operators | TicketUser | Status)[] }) => {
        const transformedFilter: { [key: string]: string[] } = {};
        for (const key in filter) {
            transformedFilter[key] = filter[key].map((item) => {
                if (typeof item === 'string') {
                    return item;
                } if ('name' in item) {
                    return item.name;
                } else if ('status' in item) {
                    return item.status;
                }
                return '';
            });
        }
        return transformedFilter;
    };

    const handleCloseTcketsAddModal = () => {
        setIsTicketsModalOpen(false);
    };

    const fetchData = (pageNum?: number) => {
        abortController.current = new AbortController();
        const filterTicketUser = selectedFilter?.['Assigned to']?.map((item) => {
            if (typeof item === 'string') return item;
            if ('name' in item) return item.name.toString();
            return '';
        }).filter(Boolean);

        const filterOperators = selectedFilter?.['Operators']?.map((item) => {
            if (typeof item === 'string') return item;
            if ('id' in item && 'value' in item) return `custom_field_${operatorsAPIresponse?.results[0]?.customFieldId}:${item.value}`;
            return '';
        }).filter(Boolean);

        const filterStatuses = selectedFilter?.['Ticket status']?.map((item) => {

            if (typeof item === 'string') return item;
            if (typeof item === 'object' && item !== null && 'status' in item) return (item as { status: string }).status;
            return '';
        }).filter(Boolean);

        const filterDate = selectedFilter?.['date'] ? selectedFilter?.['date'][0] : '';

        const params = {
            PageSize: pageSize,
            PageNumber: pageNum || defaultPageNumber,
            Assignee: filterTicketUser?.join(',') || '',
            Tags: activeTicketTag,
            Status: filterStatuses?.join(',') || '',
            Operators: filterOperators?.join(',') || '',
            SearchQuery: searchTerm.trim(),
            StartDate: typeof filterDate === 'string' ? filterDate.split(' - ')[0] : '',
            EndDate: typeof filterDate === 'string' ? filterDate.split(' - ')[1] : '',
            signal: abortController?.current?.signal,
        };

        dispatch(getTicketTable(params)).finally(() => setLoading(false));
    };



    useEffect(() => {
        if (Array.isArray(ticketTagsData)) {
            setActiveTicketTag('');
        }
    }, [ticketTagsData]);



    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (activeTicketTag || activeTicketTag === '') {
                setSelectedTickets([])
                fetchData();
            }
        }, 500);
        return () => {
            clearTimeout(timeoutId);
            if (abortController.current) {
                abortController.current.abort();
            }
        };
    }, [dispatch, searchTerm, activeTicketTag, selectedFilter]);


    useEffect(() => {
        const handleOutSideClick = (event: MouseEvent) => {
            if (bulkActionDropDownRef.current && !bulkActionDropDownRef.current.contains(event.target as Node)) {
                setIsBulkActionOpened(false);
            }
        };
        if (isBulkActionOpened) {
            document.addEventListener('mousedown', handleOutSideClick);
        }
        return () => document.removeEventListener('mousedown', handleOutSideClick);
    }, [isBulkActionOpened]);

    const handleTicketSelect = (ticketData: SelectedTicket) => {
        if (selectedTickets.some((item) => item.ticketId === ticketData.ticketId)) {
            setSelectedTickets(selectedTickets.filter((item) => item.ticketId !== ticketData.ticketId));
        } else {
            setSelectedTickets([...selectedTickets, ticketData]);
        }
    };

    const handleReassignModalClose = () => {
        setIsReassignModalOpen(false);
    };

    const handleTicketTagClick = (tagIndex: number, tagName: string) => {
        setActiveTicketTag(tagName);
        setActiveTicketTagIndex(tagIndex);
    };

    const handleFilterApply = (selectedFilterValues: { [key: string]: (string | Operators | TicketUser | Status)[] }) => {
        setSelectedFilter(selectedFilterValues);
    };

    const handleCloseBulkTicket = async () => {
        if (selectedTickets.some((item) => item.status.toLowerCase() === 'solved' || item.status.toLowerCase() === 'closed')) {
            showToaster('Close action is not applicable for closed and solved state', 'error');
            return;
        }
        await dispatch(
            bulkCloseTicket({
                ticketIds: selectedTickets.map(item => item.ticketId),
            })
        ).then((res) => {
            if (bulkCloseTicket.fulfilled.match(res)) {
                showToaster('Ticket closed successfully', 'success');
                setTimeout(() => {
                    setSelectedTickets([]);
                    fetchData()
                }, 1500)
                setIsBulkActionOpened(false)
            } else {
                if (res.payload && typeof res.payload === 'object' && 'apiMessage' in res.payload) {
                    showToaster((res.payload as { apiMessage: string }).apiMessage, 'error');
                } else {
                    showToaster('An error occurred', 'error');
                }
            }
        })
    };

    const handleBulkReassignTicket = async () => {
        if (selectedTickets.some((item) => item.status.toLowerCase() === 'solved' || item.status.toLowerCase() === 'closed')) {
            showToaster('Reassign ticket is not applicable for closed and solved state', 'error');
            return;
        }
        setIsReassignModalOpen(true)
    };


    const handleTicketReassignSuccess = () => {
        setTimeout(() => {
            fetchData()
            setSelectedTickets([]);
        }, 1500)
    }

    const handleBulkDownloadTicket = () => {
        dispatch(bulkDownloadTicket({
            ticketIds: selectedTickets.map(item => item.ticketId),
        }))
    }


    return (
        <>
            <div className="control-room-tickets">
                <div className="card-layout">
                    <div className="control-room-card-header">
                        <span>Tickets</span>
                        <button className="add-ticket" onClick={() => setIsTicketsModalOpen(true)}>
                            <img src={Plus} />
                            New ticket
                        </button>
                    </div>
                    <div className="control-room-card-body">
                        <ControlRoomTicketTags activeTicketTag={activeTicketTag} setActiveTicketTag={setActiveTicketTag} activeTicketTagIndex={activeTicketTagIndex} setActiveTicketTagIndex={setActiveTicketTagIndex} handleTicketTagSelect={handleTicketTagClick} />
                        <div className="ticket-table">
                            <div className="ticket-filter">
                                <div className="dropdown-container p-2" ref={bulkActionDropDownRef}>
                                    <div
                                        className={`bulk-actions ${selectedTickets?.length === 0 ? 'disabled' : 'enabled'}`}
                                        onClick={() => setIsBulkActionOpened(!isBulkActionOpened)}
                                    >
                                        Bulk actions {isBulkActionOpened ? <img src={ChevronUp} /> : <img src={ChevronDown} />}
                                    </div>
                                    {isBulkActionOpened && (
                                        <div className="dropdown-options">
                                            <button className="dropdown-option show-element" onClick={handleBulkReassignTicket}>
                                                Reassign ticket
                                            </button>
                                            <button className="dropdown-option show-element" onClick={handleCloseBulkTicket}>Close ticket</button>
                                            <button className="dropdown-option show-element" onClick={handleBulkDownloadTicket}>Download tickets</button>
                                        </div>
                                    )}
                                </div>

                                <div className="filter-section flex gap-x-2">
                                    <div className="serch-filter-section flex flex-row search-div relative">
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                            <img src={SearchIcon} />
                                        </span>
                                        <input
                                            className="custom-text-input"
                                            placeholder="Search by ticket, well name or operator"
                                            type="text"
                                            name="search"
                                            value={searchValue}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                    <div className="filter-div">
                                        <ControlRoomTicketFilter handleFilterApply={handleFilterApply} selectedFilter={selectedFilter} />
                                    </div>
                                </div>
                            </div>
                            <TicketTable
                                selectedTickets={selectedTickets}
                                handleTicketSelect={handleTicketSelect}
                                loading={ticketTableLoading || loading}
                                selectedFilterValues={transformSelectedFilter(selectedFilter)} // Pass transformed filter
                                searchTerm={searchTerm}
                                activeTicketTag={activeTicketTag}
                                handleModalCloseOnSuccess={handleTicketReassignSuccess}
                                selectedFilter={selectedFilter}
                                fetchData={fetchData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="model-containers">
                {isTicketsModalOpen && <TicketModelOpen
                    isOpen={isTicketsModalOpen}
                    closeModal={handleCloseTcketsAddModal}
                    fetchData={fetchData}
                />}
            </div>
            {
                isReassignModalOpen &&
                <ReassignTicketModal isModalOpen={isReassignModalOpen} handleModalClose={handleReassignModalClose} handleModalCloseOnSuccess={handleTicketReassignSuccess} selectedTicket={selectedTickets.length > 0 ? selectedTickets?.map(ticket => ticket.ticketId) : []} action={TicketAction.BULK} />

            }
        </>
    );
};

export default ControlRoomTickets;