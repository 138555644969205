import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchOperators, fetchTicketStatus, fetchTicketTable, fetchTicketTags, closeTicket, fetchTicketForms, fetchTicketUsers, reassignTicketReq, fetchCustomFields, createTicketReq, ticketUploadFileReq, fetchTicketByIdReq, fetchCommentsByTicketIdReq, fetchTicketAssignee, closeTickets, bulkReassignTicketReq, downloadTickets, updateTicketReq, fetchTicketAssigneeList } from '../services/ticketService';
import { TicketTableResponse, TicketTagResponse, Status, Operators, TicketTag, TicketUser, TicketFields, TicketForm, TicketUsersResponse, TicketTagsApiResponse, OperatorsAPIresponse, AssigneeListResponse } from '../interfaces/ticketInterface';

const zendeskRateLimitError = 'Zendesk rate limits exceeded'

interface TicketTableState {
  ticketTableData: TicketTableResponse | null;
  ticketTagsData: TicketTag[];
  ticketTagsApiResponse: TicketTagsApiResponse;
  ticketTagsLoading: boolean;
  ticketListData: TicketTableResponse;
  loading: boolean;
  ticketTableLoading: boolean;
  ticketTagLoading: boolean;
  ticketListLoading: boolean;
  ticketStatusLoading: boolean;
  ticketOperatorLoading: boolean;
  error: string | null;
  ticketStatus: Status[];
  operators: Operators[];
  operatorsAPIresponse: OperatorsAPIresponse;
  closingTicket: boolean;
  ticketForm: TicketForm[];
  ticketUsers: TicketUser[];
  ticketUserTotalCount: number;
  ticketUserAPIresponse: TicketUsersResponse;
  ticketUserLoading: boolean;
  ticketAssigneeUsers: TicketUser[];
  ticketAssigneeLoading: boolean;
  ticketAssigneeAPIresponse: TicketUsersResponse;
  TicketFields: TicketFields[];
  ticketFieldsLoading: boolean;
  reassignTicketLoading: boolean;
  createUpdateTicketLoading: boolean;
  fileUploadLoading: boolean;
  ticketTagTotalCount: number;
  ticketTotalCount: number;
  downloadTicketLoading: boolean;
  assigneeList: TicketUser[];
  assigneeListLoading: boolean;
  assigneeListCount: number;
  assigneeListresponse: AssigneeListResponse;
  ticketListRateLimitedError: string | null;
  ticketTagListRateLimitedError: string | null;

}

const initialState: TicketTableState = {
  ticketTableData: null,
  ticketTagsData: [],
  ticketTagsApiResponse: {} as TicketTagsApiResponse,
  ticketTagsLoading: false,
  ticketListData: {} as TicketTableResponse,
  loading: false,
  ticketTableLoading: false,
  ticketListLoading: false,
  ticketTagLoading: false,
  ticketStatusLoading: false,
  ticketOperatorLoading: false,
  ticketUserLoading: false,
  error: null,
  ticketStatus: [],
  operators: [],
  operatorsAPIresponse: {} as OperatorsAPIresponse,
  closingTicket: false,
  ticketForm: [],
  ticketUsers: [],
  ticketUserAPIresponse: {} as TicketUsersResponse,
  ticketAssigneeUsers: [],
  ticketAssigneeLoading: false,
  ticketAssigneeAPIresponse: {} as TicketUsersResponse,
  TicketFields: [],
  ticketFieldsLoading: false,
  reassignTicketLoading: false,
  createUpdateTicketLoading: false,
  fileUploadLoading: false,
  ticketTagTotalCount: 0,
  ticketTotalCount: 0,
  ticketUserTotalCount: 0,
  downloadTicketLoading: false,
  assigneeList: [],
  assigneeListLoading: false,
  assigneeListCount: 0,
  assigneeListresponse: {} as AssigneeListResponse,
  ticketListRateLimitedError: null,
  ticketTagListRateLimitedError: null
};


export const getTicketTable = createAsyncThunk<any, {
  PageSize: number;
  PageNumber: number;
  Tags?: string;
  Assignee?: string;
  Status?: string;
  Operators?: string;
  SearchQuery?: string;
  StartDate?: string;
  EndDate?: string;
  signal?: AbortSignal;
},
  { rejectValue: { error: string; isRateLimited: boolean } }
>(
  "ticketTable/getTicketTable",
  async (params, { rejectWithValue }) => {
    try {
      const response = await fetchTicketTable(
        params.PageSize,
        params.PageNumber,
        params.Tags ?? "",
        params.Assignee ?? "",
        params.Status ?? "",
        params.Operators ?? "",
        params.SearchQuery ?? "",
        params.StartDate ?? "",
        params.EndDate ?? "",
        params.signal
      );
      return response;
    } catch (error: any) {
      const errorMessage = error.response?.data?.apiMessage || error.message;
      const isRateLimited = errorMessage.includes("429 (Too Many Requests)") || errorMessage.includes("TooManyRequests");

      return rejectWithValue({ error: errorMessage, isRateLimited });
    }
  }
);


export const getTicketTags = createAsyncThunk<any,
  { pageNumber: number, pageSize: number, SearchQuery?: string },
  { rejectValue: { error: string; isRateLimited: boolean } }
>(
  'ticketTags/getTicketTags',
  async ({ pageNumber, pageSize, SearchQuery }: { pageNumber: number, pageSize: number, SearchQuery?: string },
    { rejectWithValue }
  ) => {
    try {
      const response: TicketTagResponse = await fetchTicketTags(pageNumber, pageSize, SearchQuery);
      return response;
    } catch (error: any) {
      const errorMessage = error.response?.data?.apiMessage || error.message;
      const isRateLimited = errorMessage.includes("429 (Too Many Requests)") || errorMessage.includes("TooManyRequests");

      return rejectWithValue({ error: errorMessage, isRateLimited });
    }
  }
);

export const getTicketStatus = createAsyncThunk(
  'ticketTable/getTicketStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchTicketStatus();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const getTicketOperators = createAsyncThunk(
  'ticketTable/getOperators',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchOperators();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const closeTicketThunk = createAsyncThunk(
  'ticket/closeTicket',
  async ({ ticketId }: { ticketId: number }, { rejectWithValue }) => {
    try {
      const response = await closeTicket(ticketId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const bulkCloseTicket = createAsyncThunk(
  'ticket/bulkCloseTicket',
  async ({ ticketIds }: { ticketIds: number[] }, { rejectWithValue }) => {
    try {
      const response = await closeTickets(ticketIds);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const bulkDownloadTicket = createAsyncThunk(
  'ticket/bulkDownloadTicket',
  async ({ ticketIds }: { ticketIds: number[] }, { rejectWithValue }) => {
    try {
      await downloadTickets(ticketIds);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTicketForm = createAsyncThunk(
  'ticketTable/getTicketForm',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchTicketForms();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const getTicketUsers = createAsyncThunk(
  'ticketTable/getTicketUsers',
  async ({ SearchQuery, PageNumber, PageSize, signal }: { SearchQuery?: string, PageNumber?: number, PageSize?: number, signal?: AbortSignal }, { rejectWithValue }) => {
    try {
      const response = await fetchTicketUsers(SearchQuery, PageNumber, PageSize, signal);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAssigneeList = createAsyncThunk(
  'ticketTable/assigneeList',
  async ({ SearchQuery, PageNumber, PageSize, signal }: { SearchQuery: string, PageNumber: number, PageSize: number, signal?: AbortSignal }, { rejectWithValue }) => {
    try {
      const response = await fetchTicketAssigneeList(SearchQuery, PageNumber, PageSize, signal);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// reassignTicket
export const reassignTicket = createAsyncThunk(
  'ticketTable/reassignTicket',
  async ({ ticketId, assigneeId }: { ticketId: number, assigneeId: number }, { rejectWithValue }) => {
    try {
      const response = await reassignTicketReq(ticketId, assigneeId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  });

// bulk reassignTicket
export const bulkReassignTicket = createAsyncThunk(
  'ticketTable/bulkReassignTicket',
  async ({ ticketIds, assigneeId }: { ticketIds: number[], assigneeId: number }, { rejectWithValue }) => {
    try {
      const response = await bulkReassignTicketReq(ticketIds, assigneeId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  });
// fetchCustomFields
export const getCustomFields = createAsyncThunk(
  'ticketTable/getCustomFields',
  async ({ fieldIds }: { fieldIds: number[] }, { rejectWithValue }) => {
    try {
      const response = await fetchCustomFields(fieldIds);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  });

// createTicketReq
export const createTicket = createAsyncThunk(
  'ticketTable/createTicket',
  async ({ ticketData }: { ticketData: any }, { rejectWithValue }) => {
    try {
      const response = await createTicketReq(ticketData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  });

// updateTicketReq
export const updateTicket = createAsyncThunk(
  'ticketTable/updateTicket',
  async ({ ticketData, ticketId }: { ticketData: any, ticketId: number }, { rejectWithValue }) => {
    try {
      const response = await updateTicketReq(ticketId, ticketData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  });

// ticketUploadFile fileName, file
export const ticketUploadFile = createAsyncThunk(
  'ticketTable/ticketUploadFile',
  async ({ fileName, file }: { fileName: string, file: any }, { rejectWithValue }) => {
    try {
      const response = await ticketUploadFileReq(fileName, file);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  });

// fetchTicketByIdReq
export const fetchTicketById = createAsyncThunk(
  'ticketTable/fetchTicketById',
  async ({ ticketId }: { ticketId: number }, { rejectWithValue }) => {
    try {
      const response = await fetchTicketByIdReq(ticketId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  });


// fetchCommentsByTicketIdReq
export const fetchCommentsByTicketId = createAsyncThunk(
  'ticketTable/fetchCommentsByTicketId',
  async ({ ticketId }: { ticketId: number }, { rejectWithValue }) => {
    try {
      const response = await fetchCommentsByTicketIdReq(ticketId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  });

// fetchTicketAssignee
export const getTicketAssignee = createAsyncThunk(
  'ticketTable/fetchTicketAssignee',
  async (page: number, { rejectWithValue }) => {
    try {
      const response = await fetchTicketAssignee(page);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  });

const ticketTableSlice = createSlice({
  name: 'ticketTable',
  initialState,
  reducers: {
    clearTicketAssetDetails: (state) => {
      state.ticketListData = {} as TicketTableResponse;
    },
    clearTicketFields: (state) => {
      state.TicketFields = [];
    },
    clearTicketUsers: (state) => {
      state.ticketUsers = [];
    },
    clearTicketTags: (state) => {
      state.ticketTagsData = [];
    }

  },
  extraReducers: (builder) => {
    builder.addCase(getTicketTable.pending, (state, action) => {
      const { PageNumber } = action.meta.arg
      if (PageNumber === 1) {
        state.ticketTableData = null;
      }
      state.loading = true;
      state.ticketTableLoading = true;
      state.error = null;
      state.ticketListRateLimitedError = null
    });
    builder.addCase(getTicketTable.fulfilled, (state, action) => {
      const { PageNumber, Tags } = action.meta.arg
      state.loading = false;
      state.ticketTableLoading = false;
      state.ticketListRateLimitedError = null

      if (PageNumber === 1) {
        state.ticketTableData = action.payload.data;
        if (Tags === '' || Tags === undefined) {
          if (!state.ticketTotalCount)
            state.ticketTotalCount = action.payload.data.totalResultsCount;
        }
      } else {
        state.ticketTableData = {
          ...state.ticketTableData,
          ...action.payload.data,
          results: state.ticketTableData?.results ? [...state.ticketTableData.results, ...action.payload.data.results] : action.payload.data.results,
        };
      }
    });

    builder.addCase(getTicketTable.rejected, (state, action) => {
      state.loading = false;
      state.ticketTableLoading = false;
      state.ticketTableData = null;
      state.ticketListRateLimitedError = action?.payload?.isRateLimited ? zendeskRateLimitError : null
    });


    builder.addCase(getTicketTags.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.ticketTagsApiResponse = {} as TicketTagsApiResponse;
      state.ticketTagLoading = true;
      state.ticketTagListRateLimitedError = null;
    });
    builder.addCase(getTicketTags.fulfilled, (state, action: any) => {
      const pageNumber = action.meta.arg.pageNumber
      state.loading = false;
      state.ticketTagLoading = false;
      state.ticketTagListRateLimitedError = null;
      if (pageNumber === 1) {
        state.ticketTagsData = action.payload.data.tags;
      } else {
        state.ticketTagsData = [...state.ticketTagsData, ...action.payload.data.tags];
      }

      state.ticketTagTotalCount = action.payload.data.count
      state.ticketTagsApiResponse = {
        ...state.ticketTagsApiResponse,
        ...action.payload.data,
        tags: state.ticketTagsApiResponse.tags ? [...state.ticketTagsApiResponse.tags, ...action.payload.data.tags] : action.payload.data.tags,
      }
      state.ticketTagsLoading = false;
    });
    builder.addCase(getTicketTags.rejected, (state, action) => {
      state.loading = false;
      state.ticketTagsApiResponse = {} as TicketTagsApiResponse;
      state.ticketTagLoading = false;
      state.ticketTagListRateLimitedError = action?.payload?.isRateLimited ? zendeskRateLimitError : null

    });
    builder.addCase(getTicketStatus.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.ticketStatusLoading = true
    });
    builder.addCase(getTicketStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.ticketStatus = action.payload.data;
      state.ticketStatusLoading = false;
    });
    builder.addCase(getTicketStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
      state.ticketStatusLoading = false
    });
    builder.addCase(getTicketOperators.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.operatorsAPIresponse = {} as OperatorsAPIresponse;
      state.ticketOperatorLoading = true;
    });
    builder.addCase(getTicketOperators.fulfilled, (state, action) => {
      state.ticketOperatorLoading = false;
      state.operators = action.payload.data?.results[0]?.customFieldOption;
      state.operatorsAPIresponse = action.payload.data;
    });
    builder.addCase(getTicketOperators.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
      state.operatorsAPIresponse = {} as OperatorsAPIresponse;
      state.ticketOperatorLoading = false;
    });
    builder.addCase(closeTicketThunk.pending, (state) => {
      state.closingTicket = true;
      state.error = null;
    });
    builder.addCase(closeTicketThunk.fulfilled, (state) => {
      state.closingTicket = false;
    });
    builder.addCase(closeTicketThunk.rejected, (state, action) => {
      state.closingTicket = false;
      state.error = action.payload as string;
    });

    //handle bulk close ticket action
    builder.addCase(bulkCloseTicket.pending, (state) => {
      state.error = null;
    });

    builder.addCase(bulkCloseTicket.rejected, (state, action) => {
      state.error = action.payload as string;
    });



    //handle bulk download ticket action
    builder.addCase(bulkDownloadTicket.pending, (state) => {
      state.downloadTicketLoading = true;
      state.error = null;
    });
    builder.addCase(bulkDownloadTicket.fulfilled, (state) => {
      state.downloadTicketLoading = false;
      state.error = null;
    });
    builder.addCase(bulkDownloadTicket.rejected, (state) => {
      state.downloadTicketLoading = false;
      // state.error = action.payload as string;
    });



    builder.addCase(getTicketForm.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getTicketForm.fulfilled, (state, action) => {
      state.loading = false;
      state.ticketForm = action.payload.data.ticket_forms;
    });
    builder.addCase(getTicketForm.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getTicketUsers.pending, (state, action) => {
      const { PageNumber } = action.meta.arg
      state.ticketUserLoading = true;
      state.error = null;
      if (PageNumber === 1) {
        state.ticketUsers = []
      }
    });
    builder.addCase(getTicketUsers.fulfilled, (state, action) => {
      const { PageNumber } = action.meta.arg
      state.ticketUserLoading = false;
      const responseData = action.payload.data
      state.ticketUserTotalCount = responseData.totalResultsCount
      state.ticketUserAPIresponse = responseData;
      if (PageNumber === 1) {
        state.ticketUsers = responseData.results;
      } else {
        state.ticketUsers = [...state.ticketUsers, ...responseData.results];
      }
    });
    builder.addCase(getTicketUsers.rejected, (state, action) => {
      state.ticketUserLoading = false;
      state.error = action.payload as string;
      state.ticketUserAPIresponse = {} as TicketUsersResponse;
      state.ticketUserLoading = false
    });

    //get assignee list
    builder.addCase(getAssigneeList.pending, (state, action) => {
      const { PageNumber } = action.meta.arg
      state.assigneeListLoading = true;
      state.error = null;
      state.assigneeListresponse = {} as AssigneeListResponse;
      if (PageNumber === 1) {
        state.assigneeList = []
      }
    });
    builder.addCase(getAssigneeList.fulfilled, (state, action) => {
      const { PageNumber } = action.meta.arg
      state.assigneeListLoading = false;
      const responseData = action.payload.data
      state.assigneeListCount = responseData.totalResultsCount
      state.assigneeListresponse = responseData
      if (PageNumber === 1) {
        state.assigneeList = responseData.results
      } else {
        state.assigneeList = [...state.assigneeList, ...responseData.results];
      }
    });
    builder.addCase(getAssigneeList.rejected, (state, action) => {
      state.assigneeListLoading = false;
      state.error = action.payload as string;
      state.assigneeListresponse = {} as AssigneeListResponse;

    });


    // getTicketAssignee
    builder.addCase(getTicketAssignee.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.ticketAssigneeLoading = true;
      state.ticketAssigneeUsers = [];
      state.ticketAssigneeAPIresponse = {} as TicketUsersResponse;
    });
    builder.addCase(getTicketAssignee.fulfilled, (state, action) => {
      const page = action.meta.arg;
      state.ticketAssigneeLoading = false;
      state.ticketAssigneeAPIresponse = action.payload.data;
      if (page === 1) {
        state.ticketAssigneeUsers = action.payload.data.results;

      } else {
        state.ticketAssigneeUsers = [...state.ticketAssigneeUsers, ...action.payload.data.results];
      }
      state.loading = false;
    });
    builder.addCase(getTicketAssignee.rejected, (state, action) => {
      state.loading = false;
      state.ticketAssigneeLoading = false;
      state.error = action.payload as string;
      state.ticketAssigneeAPIresponse = {} as TicketUsersResponse;
    });
    builder.addCase(reassignTicket.pending, (state) => {
      state.reassignTicketLoading = true;
    });
    builder.addCase(reassignTicket.fulfilled, (state) => {
      state.reassignTicketLoading = false;
    });
    builder.addCase(reassignTicket.rejected, (state) => {
      state.reassignTicketLoading = false;
    });

    builder.addCase(bulkReassignTicket.pending, (state) => {
      state.reassignTicketLoading = true;
    });
    builder.addCase(bulkReassignTicket.fulfilled, (state) => {
      state.reassignTicketLoading = false;
    });
    builder.addCase(bulkReassignTicket.rejected, (state) => {
      state.reassignTicketLoading = false;
    });

    builder.addCase(getCustomFields.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.ticketFieldsLoading = true;
    });
    builder.addCase(getCustomFields.fulfilled, (state, action) => {
      state.loading = false;
      state.TicketFields = action.payload.data;
      state.ticketFieldsLoading = false;
    });
    builder.addCase(getCustomFields.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
      state.ticketFieldsLoading = false;
    });
    // createTicket
    builder.addCase(createTicket.pending, (state) => {
      state.createUpdateTicketLoading = true;
    });
    builder.addCase(createTicket.fulfilled, (state) => {
      state.createUpdateTicketLoading = false;
    });
    builder.addCase(createTicket.rejected, (state) => {
      state.createUpdateTicketLoading = false;
    });
    // updateTicket
    builder.addCase(updateTicket.pending, (state) => {
      state.createUpdateTicketLoading = true;
    });
    builder.addCase(updateTicket.fulfilled, (state) => {
      state.createUpdateTicketLoading = false;
    });
    builder.addCase(updateTicket.rejected, (state) => {
      state.createUpdateTicketLoading = false;
    });
    // ticketUploadFile
    builder.addCase(ticketUploadFile.pending, (state) => {
      state.fileUploadLoading = true;
    });
    builder.addCase(ticketUploadFile.fulfilled, (state) => {
      state.fileUploadLoading = false;
    });
    builder.addCase(ticketUploadFile.rejected, (state) => {
      state.fileUploadLoading = false;
    });

  },
});

export const { clearTicketAssetDetails, clearTicketFields, clearTicketUsers, clearTicketTags } = ticketTableSlice.actions;

export default ticketTableSlice.reducer;
