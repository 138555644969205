import React, { useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './ControlRoomDashboard.scss';
import ControlRoomHeader from './components/header/ControlRoomHeader';
import TabWithUrlComponent from '../../components/TabWithUrl';
import ControlRoomWells from './components/Wells/ControlRoomWells';
import ControlRoomTickets from './components/tickets/components/ControlRoomTickets';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { clearVisibilityWatchListItems, emptyWellWatchList, fetchWellsWatchList } from './components/Wells/ControlRoomWellsSlice';
import { fetchAssetList, fetchAssetListTimeSeries, resetControlRoomWells } from './components/Wells/all-wells-component/AllWellSlice';
import { getTicketTable, getTicketTags } from './components/tickets/slices/ticketSlice';

const FIFTEEN_MINUTES = 15 * 60 * 1000;

const ControlRoomDashboard = () => {
    const dispatch = useAppDispatch();
    const [queryParams, setQueryParams] = useSearchParams();
    const navigate = useNavigate();

    const activeTab = queryParams.get('tab')?.toLocaleLowerCase() || 'wells';
    const tabs = [
        { label: 'Wells', component: ControlRoomWells },
        { label: 'Tickets', component: ControlRoomTickets },
        { label: 'Tasks', component: () => <h1>Tasks</h1>, disabled: true },
    ];

    const handleTabChange = (newTab: string) => {
        setQueryParams({ tab: newTab });
    };

    useEffect(() => {
        handleTabChange(activeTab);
    }
        , [navigate]);

    const abortController = useRef<AbortController | null>(null);
    const abortControllerimeSeries = useRef<AbortController | null>(null);
    const selectedWatchList = useAppSelector((state) => state.wellWatchList.selectedWatchList);

    const fetchData = () => {
        if (abortController.current) abortController.current.abort();
        abortController.current = new AbortController();

        const params = {
            pageSize: 25,
            pageNumber: 1,
            watchListId: selectedWatchList?.id || undefined,
            searchQuery: "",
            includeTimeseriesFields: false,
            signal: abortController.current.signal
        };

        dispatch(fetchAssetList(params)).then(() => fetchDataTimeSeries());
    };

    const fetchDataTimeSeries = () => {
        if (abortControllerimeSeries.current) abortControllerimeSeries.current.abort();
        abortControllerimeSeries.current = new AbortController();

        const params = {
            pageSize: 25,
            pageNumber: 1,
            watchListId: selectedWatchList?.id || undefined,
            searchQuery: "",
            includeTimeseriesFields: true,
            signal: abortControllerimeSeries.current.signal
        };

        dispatch(fetchAssetListTimeSeries(params));
    };

    const controlRoomRefreshHandler = () => {
        dispatch(emptyWellWatchList());
        dispatch(resetControlRoomWells());
        dispatch(fetchWellsWatchList({ pageSize: 25, pageNumber: 1 }));
        dispatch(clearVisibilityWatchListItems());
        fetchData();
    };

    const ticketTabRefreshHandler = async () => {
        dispatch(getTicketTable({
            PageSize: 25,
            PageNumber: 1,
        }));
        dispatch(getTicketTags({ pageNumber: 1, pageSize: 25 }))


    }

    useEffect(() => {
        const interval = setInterval(() => {
            controlRoomRefreshHandler();
        }, FIFTEEN_MINUTES);

        return () => clearInterval(interval);
    }, [selectedWatchList]);

    return (
        <div className="control-room-component">
            <ToastContainer position="top-right" autoClose={3000} />
            <ControlRoomHeader controlRoomRefreshHandler={() => {
                if (activeTab === 'wells') {
                    controlRoomRefreshHandler();
                } else if (activeTab === 'tickets') {
                    ticketTabRefreshHandler();
                }
            }} />

            <TabWithUrlComponent className='!px-[32px]' initialTab={{ tab: 'Wells' }} tabs={tabs} value='Wells' />

        </div>
    );
};

export default ControlRoomDashboard;
