import { useEffect, useRef, useState } from "react"
import { ConvertDateTimeToLocal } from "../../../../../../utilities/CommonFunctions";
import ReassignTicketModal from "../reassignTicketModal/ReassignTicketModal";
import { useAppDispatch } from "../../../../../../hooks/storeHooks";
import { clearTicketAssetDetails, closeTicketThunk, getTicketTable } from "../../slices/ticketSlice";
import { showToaster } from "../../../../../dashboard/components/asset-location/AssetList";
import './TicketCard.scss';
import { TicketAction } from "../../interfaces/ticketInterface";

type TicketCardProps = {
    id: number,
    status: string,
    subject: string,
    requestor: string,
    assignedTo: string,
    updatedDate: string,
    isTicketsModalOpen: boolean,
    setIsTicketsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectedTicketIdForClaim: React.Dispatch<React.SetStateAction<number | null>>,
    selectedTicketIdForClaim: number | null
}

const TicketCard: React.FC<TicketCardProps> = ({
    id,
    status,
    subject,
    requestor,
    assignedTo,
    updatedDate,
    setIsTicketsModalOpen,
    setSelectedTicketIdForClaim,

}) => {

    const ticketDropDownRef = useRef<HTMLDivElement>(null);
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const [reassignModalOpen, setReassignModalOpen] = useState(false);
    const [assetId, setAssetId] = useState<string>('');
    const [selectedTicket, setSelectedTicket] = useState<number>(0);


    const dispatch = useAppDispatch();

    useEffect(() => {
        const handleOutSideClick = (event: MouseEvent) => {
            if (ticketDropDownRef.current && !ticketDropDownRef.current.contains(event.target as Node)) {
                setIsContextMenuOpen(false);
            }
        };
        if (isContextMenuOpen) {
            document.addEventListener('mousedown', handleOutSideClick);
        }
        return () => document.addEventListener('mousedown', handleOutSideClick);
    }, [isContextMenuOpen]);

    const getTwoLetter = (name: string) => {
        if (!name) return ''
        return name.split('')[0]?.charAt(0).toUpperCase() + name.split('')[1]?.charAt(0).toUpperCase()
    }

    useEffect(() => {
        const selectedWellName = sessionStorage.getItem('selectedWellName');
        const selectedAssetId = selectedWellName && JSON.parse(selectedWellName || '');
        setAssetId(selectedAssetId?.assetId);
    });

    const handleCloseTicket = async (ticketId: number) => {
        await dispatch(
            closeTicketThunk({
                ticketId: ticketId,
            })
        ).then((res) => {
            if (closeTicketThunk.fulfilled.match(res)) {
                showToaster('Ticket closed successfully', 'success');
                setIsContextMenuOpen(false);
                dispatch(clearTicketAssetDetails());
                if (assetId) {
                    const params = { PageSize: 25, PageNumber: 1 };
                    dispatch(getTicketTable(params))
                }
            } else {
                if (res.payload && typeof res.payload === 'object' && 'apiMessage' in res.payload) {
                    showToaster((res.payload as { apiMessage: string }).apiMessage, 'error');
                } else {
                    showToaster('An error occurred', 'error');
                }
            }
        })
    };


    const handleTicketReassignSuccess = () => {
        if (assetId) {
            dispatch(getTicketTable({ PageSize: 5, PageNumber: 1 }));
        }
    }

    return (
        <>
            <div className="ticket-card card flex flex-col">
                <div className="ticket-card-header flex flex-row justify-between w-full items-center">
                    <div className="ticket-id">
                        {`# ${id}`}
                    </div>
                    <div className="flex flex-row items-center space-x-[8px]">
                        <div className={`ticket-status  ${status ? status.toLowerCase() : ''}`}>{status}</div>
                        <div className='dropdown-container p-[8px]'>
                            <button className={`dropdown-btn`} onClick={() => setIsContextMenuOpen((prev) => !prev)}>
                                <span className='dot'></span>
                                <span className='dot'></span>
                                <span className='dot'></span>
                            </button>

                            {isContextMenuOpen && (
                                <div className='dropdown-options' ref={ticketDropDownRef}>
                                    <button disabled={(status?.toLowerCase() === "closed" || status?.toLowerCase() === "solved") ? true : false} className={`dropdown-option show-element ${(status?.toLowerCase() === "closed" || status?.toLowerCase() === "solved") ?
                                        "btn-disabled" : ""
                                        }`} onClick={() => {
                                            setSelectedTicket(id);
                                            setReassignModalOpen(true);
                                            setIsContextMenuOpen(false);
                                        }}>Reassign ticket</button>
                                    {/* <button className='dropdown-option show-element'>Add a task / reminder</button> */}
                                    <button disabled={(status?.toLowerCase() === "closed" || status?.toLowerCase() === "solved") ? true : false} className={`dropdown-option show-element ${(status?.toLowerCase() === "closed" || status?.toLowerCase() === "solved") ?
                                        "btn-disabled" : ""
                                        }`} onClick={() => handleCloseTicket(id)}>Close ticket</button>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
                <div className="ticket-card-content flex flex-col border-bottom space-y-[8px]">
                    <div className="ticket-subject">
                        {subject}
                    </div>
                    <div className="ticket-requestor">
                        Requestor: {requestor}
                    </div>

                </div>
                <div className="ticket-card-footer flex flex-row items-center justify-between">
                    <div className="">
                        {updatedDate ? ConvertDateTimeToLocal(updatedDate) : ''}
                    </div>
                    {
                        assignedTo ?
                            <div className="ticket-assigned-to">
                                {getTwoLetter(assignedTo)}
                            </div>
                            :
                            <button className={`${status === "open" || status === "new" || assignedTo === null ? "cancel-btn" :
                                "hidden-claim"
                                }`} onClick={() => {
                                    console.log("clicked");

                                    setSelectedTicketIdForClaim(id);
                                    setIsTicketsModalOpen(true);
                                }}>
                                Claim
                            </button>

                    }

                </div>
            </div>
            {reassignModalOpen &&
                <ReassignTicketModal
                    isModalOpen={reassignModalOpen}
                    handleModalClose={() => setReassignModalOpen(false)}
                    selectedTicket={selectedTicket !== null ? [selectedTicket] : []}
                    handleModalCloseOnSuccess={handleTicketReassignSuccess}
                    action={TicketAction.SINGLE}
                />
            }



        </>
    )
}

export default TicketCard