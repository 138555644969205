import React, { useEffect, useState } from 'react';
import SetPointList from './components/SetPointList';
import SetPointDetails from './components/SetPointDetails';
import './SetPoints.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { fetchSetPoint, resetReadDeviceStatus } from './SetPointsSlice';
import Loader from '../../../common/page-loader/ComponentLoader';
// import chevronDown from '../../../../images/chevron-down.svg';
import { updateAssetControlScanStatus } from '../../../asset/AssetControlSlice';
import SetPointToast from '../../../common/toast/SetPointToast';
import { AppUser } from '../../../user/model/AppUser';
// import { Role } from '../../../../models/Role';
import { useWebSocket } from '../../../../hooks/webSocketHook';
import { WebSocketActionType } from '../asset-details-header/AssetControlActions';
import { Role } from '../../../../models/Role';

interface TabsProps {
  value?: string | undefined;
}

const SetPoints: React.FC<TabsProps> = ({ value }) => {
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const assetControlData = useAppSelector((state) => state?.assetControl)
  const wellId = selectedAsset.selectedAssetId;
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.setPoint.loading);
  const [selectedSetpointGroup, setSelectedSetpointGroup] = useState<number | null>(null);
  const [selectedSetPointsAddress, setSelectedSetPointsAddress] = useState<string | undefined>()
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const setPointData = useAppSelector((state) => state.setPoint)


  const loggedInUser = useAppSelector((state) => state?.user?.loggedInUser);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  const initialUserState = loggedInUser || storedUser || undefined;

  const { establishWebSocketConnection } = useWebSocket()
  useEffect(() => {
    if (wellId) {
      dispatch(fetchSetPoint(wellId));
      console.log(value)
      resetSetPointStatus()
    }
    if (assetControlData?.assetControlScanStatus) {
      dispatch(updateAssetControlScanStatus(false))
    }
  }, [selectedAsset, assetControlData?.assetControlScanStatus === true]);



  const handleSetPointGroupSelect = (setpointGroup: number, selectedAddress: string | undefined) => {
    dispatch(resetReadDeviceStatus('All'))
    setSelectedSetPointsAddress(selectedAddress)
    setSelectedSetpointGroup(setpointGroup);
  };
  // const clickDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };

  const readDevice = () => {
    if (selectedSetPointsAddress) {
      establishWebSocketConnection({ webSocketActionType: WebSocketActionType.ReadDevice, selectedSetPointAddress: selectedSetPointsAddress })
    }
  }

  const updateDevice = () => {
    if (setPointData?.changedBackupValues) {
      establishWebSocketConnection({ webSocketActionType: WebSocketActionType.UpdateDevice, changedBackupValues: setPointData.changedBackupValues })
    }
  }
  const resetSetPointStatus = () => {
    dispatch(resetReadDeviceStatus('All'))
    setSelectedSetPointsAddress(undefined)
    setSelectedSetpointGroup(null);
  }
  return (
    <>
      <div className='profile-container w-100 !p-9'>

        {
          (setPointData?.readDeviceLoading || setPointData?.readDeviceSuccessStatus || setPointData?.readDeviceErrorStatus || setPointData?.readDeviceReturnErrorStatus) &&
          <SetPointToast isShowEnabled={setPointData?.readDeviceLoading || setPointData?.readDeviceSuccessStatus || setPointData?.readDeviceErrorStatus || setPointData?.readDeviceReturnErrorStatus} />
        }
        {loading ? (
          <div className='set-point-loader flex items-center justify-center w-100'>
            <Loader />
          </div>) : (
          <div className='profile-status-header'>
            <div className='tab-content set-point-wrap mt-0'>
              <div className='card profile-card m-0'>
                <div className='header'>
                  <span className='title'>Setpoints </span>
                  <div>Select the group of registers that you would like to modify</div>
                </div>
                <div className='body user-details-body'>
                  <div className='btn-wrapper'>
                    <button className={`setpoint-btn ${selectedSetpointGroup && 'selected'}`} onClick={readDevice}>Read device</button>
                    {/* <button className={`setpoint-btn ${setPointData?.deviceReadStatus && assetControlData.activeAssetControlActionAssetId === wellId && 'selected'}`}>Backup Setpoints</button> */}
                    <button className={`setpoint-btn ${setPointData?.deviceReadStatus && assetControlData.activeAssetControlActionAssetId === wellId && (initialUserState?.roles.includes(Role.Assetcontrol) || initialUserState?.roles.includes(Role.Admin)) ? 'selected' : 'pointer-events-none'}`} onClick={() => updateDevice()}>Update device</button>
                    {/* <button className={`setpoint-btn synchronize-dropdown ${(initialUserState?.roles.includes(Role.Assetcontrol) || initialUserState?.roles.includes(Role.Admin)) ? 'enabled' : ''}`} onClick={() => { (initialUserState?.roles.includes(Role.Assetcontrol) || initialUserState?.roles.includes(Role.Admin)) && clickDropdown() }}>
                      Synchronize All <img src={chevronDown} alt='chevron' className={`${isDropdownOpen ? 'rotate-180' : ''}`} />

                      {isDropdownOpen && (
                        <div className='dropdown-options synchronize-option'>
                          <button>Make a backup copy</button>
                          <button>Send backup copy to device</button>
                        </div>
                      )}
                    </button> */}
                  </div>

                  <div className='adminuser-block'>
                    <div className='flex flex-row'>
                      <div className='basis-1/5 first-half'>
                        <SetPointList onSelectSetPointGroup={handleSetPointGroupSelect} />
                      </div>
                      <div className='basis-4/5'>
                        <div className='setpoint-userblock p-6'>
                          <SetPointDetails selectedSetpointGroup={selectedSetpointGroup} wellId={wellId as string} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        }
      </div>
    </>
  );
};

export default SetPoints;
