export interface Ticket {
  id: number;
  subject: string;
  assignee: string;
  createdAt: string;
  updatedAt: string;
  wellName: string;
  requester: string;
  requested: number;
  operator: string;
  status: string;
  priority: string | null;
  tags: string[];
}

export interface TicketTableResponse {
  results: Ticket[];
  totalResultsCount: number;
  pageNumber: number;
  pageSize: number | null;
  totalPages: number | null;
  nextPageNumber: number | null;
  prevPageNumber: number | null;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  success: boolean;
  apiMessage: string;
  statusCode: number;
  stackTrace: string | null;
}

export interface TicketTag {
  name: string;
  count: number;
}

export interface TicketTagResponse {
  data: TicketTag[];
  success: boolean;
  apiMessage: string;
  statusCode: number;
  stackTrace: string | null;
}

export interface TicketTableListResponse {
  data: TicketTableResponse;
  success: boolean;
  apiMessage: string;
  statusCode: number;
}

export interface Status {
  id: number;
  status: string;
}

export interface Operators {
  customerId: number;
  customerName: string;
}
export interface Assignees {
  assigneeId: number;
  assigneeName: string;
}

export interface Categories {
  requestorId: number;
  requestorName: string;
}

export interface Requester {
  requestorId: number;
  requestorName: string;
}

export interface TicketForm {
  id: number;
  name: string;
  ticket_field_ids: number[];
}

export interface TicketForm {
  id: number;
  name: string;
}
export interface Status {
  id: number;
  status: string;
}

export interface Operators {
  id: number;
  name: string;
  value?: string;
}


export interface CustomFieldOption {
  id: number;
  name: string;
  raw_name: string;
  value: string;
  default: boolean;
}

interface Result {
  customFieldId: number;
  customFieldOption: CustomFieldOption[];
}

export interface OperatorsAPIresponse {
  results: Result[];
  totalResultsCount: number;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  nextPageNumber: number | null;
  prevPageNumber: number | null;
  hasPrevPage: boolean;
  hasNextPage: boolean;
}


export interface Assignees {
  assigneeId: number;
  assigneeName: string;
}

export interface Categories {
  requestorId: number;
  requestorName: string;
}

export interface Requester {
  requestorId: number;
  requestorName: string;
}

export interface TicketForm {
  id: number;
  name: string;
  ticket_field_ids: number[];
}

export interface TicketForm {
  id: number;
  name: string;
}

export interface Wells {
  wellId: number;
  wellName: string;
}

export interface TicketUser {
  id: number;
  name: string;
  email: string;
}

export interface TicketUsersResponse {
  results: TicketUser[];
  pageNumber: number;
  pageSize: number;
  totalResultsCount: number;
  totalPages: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;

}

export interface AssigneeListResponse {
  results: TicketUser[];
  pageNumber: number;
  pageSize: number;
  totalResultsCount: number;
  totalPages: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;

}

export interface TicketTagsApiResponse {
  count: number;
  next_page: string | null;
  previous_page: string | null;
  tags: TicketTag[];
}


export interface custom_field_options {
  id: number;
  value: string;
}
export interface TicketFields {
  id: number;
  title: string;
  type: string;
  description: string;
  options: string[];
  custom_field_options: custom_field_options[];
}

export interface TicketById {
  url: string;
  id: number;
  external_id: string | null;
  via: {
    channel: string;
    source: {
      from: { address: string | null; name: string | null };
      to: { address: string | null; name: string | null };
      rel: string | null;
    };
  };
  created_at: string;
  updated_at: string;
  generated_timestamp: number;
  type: string | null;
  subject: string;
  raw_subject: string;
  description: string;
  priority: string | null;
  status: string;
  recipient: string | null;
  requester_id: number;
  submitter_id: number;
  assignee_id: number;
  organization_id: number | null;
  group_id: number;
  collaborator_ids: number[];
  follower_ids: number[];
  email_cc_ids: number[];
  is_public: boolean;
  due_at: string | null;
  tags: string[];
  custom_fields: { id: number; value: string | number | boolean | null | any[] }[];
  fields: { id: number; value: string | number | boolean | null | any[] }[];
  followup_ids: number[];
  ticket_form_id: number;
}

export interface FetchTicketResponse {
  data: {
    ticket: TicketById;
  };
  success: boolean;
  apiMessage: string;
  statusCode: number;
  stackTrace: string | null;
}

export interface TicketReassignData {
  id: string;
  assignee_id: string;
}

export enum TicketAction {
  SINGLE = 'single',
  BULK = 'bulk'
}