import React, { useState, useEffect, useRef } from 'react';
import WellControlOptions from './WellControlOptions';
import './asset-details-header.scss';
import { Options } from '../../data/Options';
import { wellOptions } from '../../data/WellOptions';
import { useNavigate } from 'react-router-dom';
import { updateAsset } from '../../../navigation/AssetGroupsSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import refreshIcon from '../../../../images/refresh-cw-02.svg';
import { GetLocalTimeFormat } from '../../../../utilities/CommonFunctions';
import { AppUser } from '../../../user/model/AppUser';
import { fetchAggregateValue, fetchAssetDetail, fetchXspocAggregateValue } from '../details-table/AssetDetailSlice';
import { AssetDetailsResponseProps } from '../details-table/AssetDetailProps';
import { looupKeys } from './AssetDetailsHeaderService';
import Scheduled from '../../../../../src/images/calendar-date.svg';
import InProgress from '../../../../../src/images/contrast-02.svg';
import Completed from '../../../../../src/images/check-green.svg';
import Failed from '../../../../../src/images/alert-circle.svg';
import Canceled from '../../../../../src/images/x-circle.svg';
import { fetchScheduleSpeedChangeStatus } from './AssetDetailsHeaderSlice';
import { scheduleStatus, scheduleStatusMessage } from '../schduled-speed-change/model/ScheduleSpeedChange';

interface AssetList {
  assetName: string | undefined;
  assetId: string | undefined;
  industryApplicationId: number | undefined;
}

const AssetDetailsHeader: React.FC<{
  groupName?: string;
  assetList: AssetList[];
  selectedOption: Options | undefined;
  setSelectedOption: (option: Options) => void;
}> = ({ groupName, selectedOption, setSelectedOption, assetList }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleOptionSelect = (option: Options) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };
  const [lastUpdatedDateTime, setLastUpdatedDateTime] = useState('');
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  const selectedAssetNameRef = useRef<string>('');
  const wellStatus = useAppSelector((state) => state?.assetControl?.wellStatus);
  const assetControlData = useAppSelector((state) => state?.assetControl)
  const scheduleSpeedChangeStatus = useAppSelector((state) => state?.ScheduleSpeedStatus?.status);
  const [assetId, setAssetId] = useState<string>('');

  const storedUserGroup = localStorage.getItem('jwtAccessGroups');
  const accessGroups: string[] | null = storedUserGroup ? JSON.parse(storedUserGroup) : null;

  const selectedGroupName = sessionStorage.getItem("selectedGroupName");

  const selectedRef = useRef<HTMLLIElement | null>(null);


  let initialUserState = useAppSelector((state) => state?.user?.currentUser);
  if (initialUserState?.id != storedUser?.id)
    initialUserState = storedUser
  const refreshTime = () => {
    // const date = new Date();
    // const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    setLastUpdatedDateTime(GetLocalTimeFormat());
  };

  useEffect(() => {
      const selectedWellName = sessionStorage.getItem('selectedWellName');
      const selectedAssetId = selectedWellName && JSON.parse(selectedWellName || '');
      setAssetId(selectedAssetId?.assetId);
    });

    useEffect(() => {
      if (assetId) {
          dispatch(fetchScheduleSpeedChangeStatus({ wellId: assetId }));
      }
  }, [dispatch, assetId])

  useEffect(() => {
    refreshTime();
    // setSelectedOption({
    //   wellId: '',
    //   wellName: ,
    // });
  }, []);

  const handleRefreshClick = () => {
    // window.location.reload()
    const href = window.location.href;
    window.location.href = href;

  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleWellOptionChange = async (option: AssetList) => {
    const assetId = option?.assetId ?? ''
    const assetName = option?.assetName ?? ''

    setSelectedOption({
      wellId: assetId,
      wellName: option?.assetName ?? '',
      assetGroupName: groupName ? groupName : '',
    });

    if (assetName) {
      const selectedAsset: { assetId: string, assetName: string, assetGroupName: string } = { assetId: assetId, assetName: option?.assetName ?? '', assetGroupName: groupName ? groupName : '' };
      if (option.assetName) dispatch(updateAsset(selectedAsset));
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }
  };

  useEffect(() => {
    handleOptionSelect(wellOptions[0]);

  }, []);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement && event.target.closest('.select-name')) return;
      setIsDropdownOpen(false);
    };
    document.body.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.body.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    if (selectedAssetNameRef.current !== selectedOption?.wellName) {
      selectedAssetNameRef.current = selectedOption?.wellName || '';
      if (selectedAssetNameRef.current) {
        getAssetDetail()
        dispatch(fetchAggregateValue(looupKeys.sleAggregationRange))
        dispatch(fetchXspocAggregateValue(looupKeys.xspocAggregationRnge))
      }
    }
  }, [selectedOption])

  const getAssetDetail = async () => {
    if (initialUserState?.id)

      await dispatch(fetchAssetDetail({ wellName: selectedOption?.wellName ?? '' }))
        .unwrap()
        .then((response: AssetDetailsResponseProps) => {
          sessionStorage.setItem('assetCreationDate', response.assetDetails?.creationDateTime ?? '');

          const selectedWellNameString = sessionStorage.getItem('selectedWellName');
          if (selectedWellNameString && JSON.parse(selectedWellNameString)?.assetId !== response?.assetDetails?.assetId) {
            const asset = { ...JSON.parse(selectedWellNameString), assetId: response?.assetDetails?.assetId }
            dispatch(updateAsset(asset))
            sessionStorage.setItem('selectedWellName', JSON.stringify(asset))
          }

        })
        .catch((error) => {
          console.error('Failed to fetch AssetDetails  details:', error);
        });
  }

  const handleAssetGroupNameClick = () => {
    if (accessGroups && accessGroups.length > 0) {
      navigate("/")
    } else {
      if (groupName)
        sessionStorage.setItem("selectedGroupName", groupName)
      navigate('/layout/group-dashboard', { state: { groupNameSet: groupName } });
    }
  }


  function getStatusMessage(statusCode: number) {
    const statusKey = Object.keys(scheduleStatus).find(
      (key) => scheduleStatus[key as keyof typeof scheduleStatus] === statusCode
    );

    if (statusKey) {
      return scheduleStatusMessage[statusKey as keyof typeof scheduleStatusMessage];
    }
    return '';
  }

  useEffect(() => {
    if (isDropdownOpen && selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isDropdownOpen]);

  return (
    <div className='dashboard-first-block'>
      <div className='top-header flex'>
        <div className='col-8'>
          <h3 className='page-title' onClick={() => handleAssetGroupNameClick()}>
            {accessGroups && accessGroups.length > 0 ? selectedGroupName : groupName === '-Clients' ? 'Well Groups' : groupName}
          </h3>
          <div className='select-name'>
            <div className={`custom-dropdown ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
              <div className='selected-option'>
                {selectedOption?.wellName}
                <span>&#9660;</span>
              </div>
              {isDropdownOpen && (
                <ul className='dropdown-list max-height'>
                  {assetList.map((option, index) => (
                    <li 
                    key={index} 
                    ref={option.assetName === selectedOption?.wellName ? selectedRef : null} 
                    onClick={() => handleWellOptionChange(option)}
                    className={option.assetName === selectedOption?.wellName ? 'dropdown-selected-item' : ''}
                    >
                      {option.assetName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className='badges-section' >
                       {wellStatus?.runStatus && <span className={`well-status ${assetControlData?.assetControlLoading ? '' : wellStatus?.runStatus ? (wellStatus?.runStatus?.toLocaleLowerCase()?.includes('run') ? 'running' : (wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle')) ? 'idle' : 'blank') : 'blank'}`}>{assetControlData?.assetControlLoading ? '' : wellStatus?.runStatus ? (wellStatus?.runStatus?.toLocaleLowerCase()?.includes('run') ? 'Running' : (wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle')) ? 'Shutdown' : '-') : '-'}</span>}
                        {assetControlData?.operatingMode && <span className={`operating-mode ${(assetControlData?.operatingMode && !assetControlData?.assetControlLoading) ? 'exist' : ''}`}>{assetControlData?.assetControlLoading ? '' : assetControlData?.operatingMode ?? ''}</span> }
                        {(scheduleSpeedChangeStatus === scheduleStatus.InProgress || scheduleSpeedChangeStatus === scheduleStatus.Pending  ) &&
                          <span className={`sm-highlt ${scheduleSpeedChangeStatus !== null && scheduleSpeedChangeStatus !== undefined ? scheduleStatus[scheduleSpeedChangeStatus] : ''}`}>
                          {
                            scheduleSpeedChangeStatus === scheduleStatus.InProgress ?
                              <img src={InProgress} alt='speed-change' />
                              : scheduleSpeedChangeStatus === scheduleStatus.Pending ?
                                <img src={Scheduled} alt='speed-change' />
                                : scheduleSpeedChangeStatus === scheduleStatus.Completed ?
                                  <img src={Completed} alt='speed-change' />
                                  : scheduleSpeedChangeStatus === scheduleStatus.Failed ?
                                    <img src={Failed} alt='speed-change' />
                                    : scheduleSpeedChangeStatus === scheduleStatus.Cancelled ?
                                      <img src={Canceled} alt='speed-change' />
                                      : scheduleSpeedChangeStatus === scheduleStatus.Shutdown ?
                                        <img src={Failed} alt='speed-change' />
                                        : ''

                          }
                          {getStatusMessage(scheduleSpeedChangeStatus || 0)}

                        </span>
                        } 
                    </div>
        </div>
        <div className='flex col-4 justify-end'>
          <div className='inner-wrapper'>
            <div>
              <WellControlOptions
                onSelectOption={handleOptionSelect}
                selectedOption={selectedOption || wellOptions[0]}
              />
            </div>
            <div className='pipe'>|</div>
            <div className='flex justify-end' onClick={handleRefreshClick}>
              <p className='text-timestamp'>Last updated on {lastUpdatedDateTime}</p>
              <img src={refreshIcon} alt='refresh-icon' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetDetailsHeader;
