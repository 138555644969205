import React from 'react';
import Close from '../../../../../../images/x-close.svg';
import CustomModal from '../../../../../dashboard/components/graph/ExpandModel';
import IntakePressureChart from './IntakePressureChartModal';

interface IntakePressureModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    selectedIntakePressureData: any;
    heading: string;
    modelWidth?: string;
    modelClass?:string;
}

const IntakePressureModal: React.FC<IntakePressureModalProps> = ({ 
    isOpen,
    onRequestClose,
    selectedIntakePressureData,
    heading,
    modelWidth,
    modelClass, 
 }: IntakePressureModalProps) => {
    return (
        <CustomModal isOpen={isOpen} onRequestClose={onRequestClose} width={modelWidth} classname={modelClass}>
            <div className='modal-header'>
                <div className='flex'>
                    <div className='grow'>
                        <h3>{heading}</h3>
                    </div>
                    <div className='flex-none'>
                        <button onClick={onRequestClose}>
                        <img src={Close} alt='Expand' />
                        </button>
                    </div>
                </div>
            </div>
            <div className='modal-content h-full'>
                <div className='flex flex-wrap items-center justify-center wellChart-container-dashboard nodata'>
                    <div className='flex w-full h-full upTime-modal-body'>
                       <div  className="w-full"><IntakePressureChart selectedIntakePressureData={selectedIntakePressureData}/></div>
                    </div>           
                </div>
            </div>
        </CustomModal>
    )
}

export default IntakePressureModal;