import React, { useRef, useCallback, useEffect } from 'react';
import ChevronDown from '../../../../../images/chevron-down.svg';
import ChevronUp from '../../../../../images/chevron-up.svg';
import './SelectDropdown.scss';
import Loader from '../../../../common/page-loader/ComponentLoader';

interface TicketFilterDropDownProps {
    options: any[];
    name: string;
    isDropDownOpen: boolean;
    handleDropDownClick: (dropDownName: string) => void;
    selectedFilter: any;
    handleFilterClick: (key: string, option: any) => void;
    idKey: string;
    nameKey: string;
    hasMore: boolean;
    loadMore: () => void;
    loading: boolean;
    onSearch: (query: string) => void;
    searchTerm: string;
    setSearchTerm: (query: string) => void;
    validateField?: (fieldName: string, value: any) => void;
}

const SelectDropdown: React.FC<TicketFilterDropDownProps> = ({
    name,
    isDropDownOpen,
    handleDropDownClick,
    options,
    selectedFilter,
    handleFilterClick,
    idKey,
    nameKey,
    hasMore,
    loadMore,
    loading,
    onSearch,
    searchTerm,
    setSearchTerm,
    //validateField
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    // Handle infinite scrolling
    const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + clientHeight) < 50 && hasMore && !loading) {
            loadMore();
        }
    }, [hasMore, loading, loadMore]);

    // Clear search when dropdown closes
    useEffect(() => {
        if (!isDropDownOpen) {
            setSearchTerm('');
            if (searchTerm) onSearch('');
        }
    }, [isDropDownOpen]);

   /*  useEffect(() => {
        if (selectedFilter !== undefined) {
            console.log('name',name)
            validateField(name, selectedFilter.name || '');
        }
    }, [selectedFilter]); */

    // Memoized filtered options to prevent re-renders
    const filteredOptions = React.useMemo(() =>
        options?.filter(option => option[nameKey].toLowerCase().includes(searchTerm.toLowerCase())),
        [options, nameKey, searchTerm]
    );


    return (
        <div className="control-room-ticket-dropdown cursor-pointer" ref={dropdownRef}>
            <div onClick={() => handleDropDownClick(name)} className='ticket-drop-down-btn'>
                <span className='ticket-title'>{name}</span>
                <div className='flex justify-center items-center space-x-6'>
                    <span className='w-38 h-6 text-[#F7F9F9] flex justify-center items-center select-title'>
                        {selectedFilter?.name}
                    </span>
                    <span>{isDropDownOpen ? <img src={ChevronUp} /> : <img src={ChevronDown} />}</span>
                </div>
            </div>
            {isDropDownOpen && (
                <div className="max-h-[400px] overflow-y-auto" onScroll={handleScroll}>
                    <div className='operators-search-filter pt-3 sticky top-0 bg-[#131F2E]'>
                        <div className='w-98 pb-4 px-1'>
                            <label className='relative flex items-center'>
                                <span className='sr-only'>Search</span>
                                <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                                    <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 20'>
                                        <path
                                            fillRule='evenodd'
                                            d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                                            clipRule='evenodd'
                                        ></path>
                                    </svg>
                                </span>
                                <input
                                    className='custom-text-input !pl-8'
                                    placeholder='Search'
                                    type='text'
                                    value={searchTerm}
                                    onChange={e => {
                                        const query = e.target.value;
                                        setSearchTerm(query);
                                        onSearch(query);
                                    }}
                                />
                            </label>
                        </div>
                    </div>
                    {filteredOptions?.map(filteredOption => (
                        <div
                            onClick={() => handleFilterClick(name, filteredOption)}
                            className='ticket-filter-options cursor-pointer'
                            key={filteredOption[idKey]}
                        >
                            <span className='ms-6 break-all w-[95%]'>{filteredOption[nameKey]}</span>
                        </div>
                    ))}
                    {loading && (
                        <div className="flex justify-center p-2">
                            <Loader />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SelectDropdown;
