import React, { useEffect, useState, useCallback } from 'react';
import CardLayout from '../layout/CardLayout';
import BuildWatchListModal from './buildWatchlistModal/BuildWatchListModal';
import AllWells from './all-wells-component/AllWells';
import WatchWellsList from './wellCardList/WellCardList';
import BuildWatchlistFiltersModal from './buildWatchlistFiltersModal/BuildWatchlistFiltersModal';
import './ControlRoomWells.scss';
import { useAppDispatch } from '../../../../hooks/storeHooks';
import {
    clearVisibilityWatchListItems,
    createWatchList,
    emptyWellWatchList,

    fetchWellsWatchList,
    setSelectedWatchList,
    updateWatchList,
    updateWatchListById,
} from './ControlRoomWellsSlice';
import { Parameter, WellWatchList } from '../interfaces/ControlRoomWells.interface';
import { showToaster } from '../../../dashboard/components/asset-location/AssetList';
import { useNavigate, useSearchParams } from 'react-router-dom';

export interface FilterItem {
    id: string;
    name: string;
    isChecked: boolean;
}

export interface SelectedFilters {
    operators: Parameter[],
    locations: Parameter[],
    statuses: Parameter[],
    parameters: Parameter[],
    name: string,
    description: string,
}

const ControlRoomWells = () => {
    const [isBuildWatchlistModalOpen, setIsBuildWatchlistModalOpen] = useState(false);
    const [isBuildWatchlistFiltersModalOpen, setIsBuildWatchlistFiltersModalOpen] = useState(false);
    const [activeWell, setActiveWell] = useState<WellWatchList>({} as WellWatchList);
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useSearchParams();
    const activeTab = queryParams.get('tab')?.toLocaleLowerCase() || 'wells';


    const handleTabChange = (newTab: string) => {
        setQueryParams({ tab: newTab });
    };

    useEffect(() => {
        handleTabChange(activeTab);
    }
        , [navigate]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (activeTab === 'wells') {
            dispatch(fetchWellsWatchList({ pageSize: 25, pageNumber: 1 }));
            dispatch(clearVisibilityWatchListItems());
            dispatch(emptyWellWatchList())
        }
    }, [activeTab]);



    const toggleModal = useCallback((modalType: 'watchlist' | 'filters', isOpen: boolean) => {
        if (modalType === 'watchlist') {
            setIsBuildWatchlistModalOpen(isOpen);
        } else if (modalType === 'filters') {
            setIsBuildWatchlistFiltersModalOpen(isOpen);
            if (!isOpen) {
                setActiveWell({} as WellWatchList);
            }
        }
    }, []);

    const handleCreateWatchlist = useCallback(() => {
        toggleModal('watchlist', false);
        toggleModal('filters', true);
    }, [toggleModal]);

    const handleCreateOrUpdateWatchlistFilters = useCallback(async (payload: SelectedFilters, isUpdate: boolean) => {
        try {
            const finalPayload = {
                ...payload?.operators?.length && { operators: payload?.operators?.map(item => item?.id) },
                ...payload?.locations?.length && { locations: payload?.locations?.map(item => item?.id) },
                ...payload?.statuses?.length && { statuses: payload?.statuses?.map(item => item?.id) },
                ...payload?.parameters?.length && { parameters: payload?.parameters?.map(item => ({ id: item?.id, from: item?.from, to: item?.to })) },
                ...payload?.name && { name: payload?.name.trim() },
                ...payload?.description && { description: payload?.description?.trim() },
            };

            let response;
            if (isUpdate) {
                response = await dispatch(updateWatchList({ id: activeWell?.id, payload: finalPayload }))
            } else {
                response = await dispatch(createWatchList(finalPayload));
            }

            if (response?.payload?.success) {
                if (isUpdate) {
                    showToaster('Watchlist updated successfully', 'success');
                    dispatch(updateWatchListById(response?.payload?.data));
                } else {
                    showToaster('Watchlist created successfully', 'success');
                    setActiveWell({} as WellWatchList);
                    dispatch(emptyWellWatchList());
                    await dispatch(fetchWellsWatchList({ pageSize: 25, pageNumber: 1 }));
                    dispatch(clearVisibilityWatchListItems());
                }
                toggleModal('filters', false);
                dispatch(setSelectedWatchList({} as WellWatchList));
            } else {
                if (response?.payload?.statusCode === 400) {
                    showToaster(response?.payload?.apiMessage, 'error');
                } else {
                    showToaster(isUpdate ? 'Failed to update watchlist' : 'Failed to create watchlist', 'error');
                }
            }
        } catch (error) {
            showToaster('Failed to create watchlist', 'error');
        }
    }, [dispatch, toggleModal, activeWell]);

    return (
        <div className='control-room-well'>
            <>
                <CardLayout
                    headerRight={
                        <div className='header-right'>
                            <button
                                onClick={() => toggleModal('filters', true)}
                                className='btn btn-primary border border-danger'
                            >
                                <span className='btn-icon'>+</span> Build watchlist
                            </button>
                        </div>
                    }
                    title='Well watchlists'
                    children={
                        <WatchWellsList
                            setIsBuildWatchlistFiltersModalOpen={setIsBuildWatchlistFiltersModalOpen}
                            setActiveWell={setActiveWell}
                            activeWell={activeWell}
                        />
                    }
                />

                {isBuildWatchlistModalOpen && (
                    <BuildWatchListModal
                        CreateBtnHandler={handleCreateWatchlist}
                        closeModal={() => toggleModal('watchlist', false)}
                    />
                )}

                {isBuildWatchlistFiltersModalOpen && (
                    <BuildWatchlistFiltersModal
                        closeModal={() => toggleModal('filters', false)}
                        createBtnHandler={(payload) => handleCreateOrUpdateWatchlistFilters(payload, false)}
                        updateBtnHandler={(payload) => handleCreateOrUpdateWatchlistFilters(payload, true)}
                        activeWell={activeWell}
                    />
                )}

                <AllWells toggleModal={toggleModal} />
            </>
        </div>
    );
};

export default ControlRoomWells;
