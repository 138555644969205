import { useEffect, useMemo, useRef, useState } from 'react';
import arrowsquare from '../../../../../../images/arrow-square-right.svg';
import TicketFilterDropDown from '../controlRoomTicketFilterDropDown/TicketFilterDropDown';
import Close from '../../../../../../images/x-close-blue.svg';
import calendarIcon from '../../../../../../images/calendar-icon.svg';
import { customStaticRanges, defineds, formateDate } from '../../../../../common/DateRangeSelector/DefaultRanges';
import DateRangeSelector from '../../../../../common/DateRangeSelector/DateRangeSelector';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { Operators, TicketUser, Status } from '../../interfaces/ticketInterface';
import { getAssigneeList, getTicketOperators, getTicketStatus } from '../../slices/ticketSlice';

interface ControlRoomFilterModal {
    onClose: () => void;
    handleFilterApply: (selectedFilter: { [key: string]: (Operators | TicketUser | Status | string)[] }) => void;
    selectedFilter: { [key: string]: (Operators | TicketUser | Status | string)[] };
}

type ControlRoomTicketSelectedFilterProps = {
    [key: string]: (Operators | TicketUser | Status | string)[];
};

const ControlRoomFilterModal: React.FC<ControlRoomFilterModal> = ({ onClose, handleFilterApply, selectedFilter }) => {
    const dispatch = useAppDispatch()
    const [selectedFilterDropDown, setSelectedFilterDropDown] = useState<string | null>(null);
    const [selectedTicketFilter, setSelectedTicketFilter] = useState<ControlRoomTicketSelectedFilterProps>(selectedFilter);
    const { ticketStatus, operators, assigneeList, ticketStatusLoading, ticketOperatorLoading, assigneeListLoading, assigneeListresponse } = useAppSelector((state) => state.ticketTableSlice);

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [showCalendar, setShowCalendar] = useState(false);

    const calendarRef = useRef<HTMLDivElement>(null);

    const pageSize = 100
    const defaultPageNumber = 1

    const [assigneeSearchTerm, setAssigneeSearchTerm] = useState('')
    const assigneeSearchValue = useMemo(() => assigneeSearchTerm, [assigneeSearchTerm]);
    const abortController = useRef<AbortController | null>(null);

    useEffect(() => {
        if (ticketStatus?.length === 0) dispatch(getTicketStatus());
        if (operators?.length === 0) dispatch(getTicketOperators());
    }, [])

    const fetchData = (pageValue: number) => {
        abortController.current = new AbortController();
        assigneeList?.length === 0 && dispatch(getAssigneeList({ SearchQuery: assigneeSearchValue.trim(), PageNumber: pageValue, PageSize: pageSize, signal: abortController?.current?.signal }));
    }


    useEffect(() => {
        setAssigneeSearchTerm('')
    }, [selectedFilterDropDown])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchData(defaultPageNumber);

        }, 500);
        return () => {
            clearTimeout(timeoutId);
            if (abortController.current) {
                abortController.current.abort();
            }
        };
    }, [dispatch, assigneeSearchTerm]);

    useEffect(() => {
        const handleOutSideClick = (event: MouseEvent) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
                setShowCalendar(false);
            }
        };
        if (showCalendar) {
            document.addEventListener('mousedown', handleOutSideClick);
        }
        if (showCalendar) {
            setSelectedFilterDropDown(null);
        }
        return () => document.removeEventListener('mousedown', handleOutSideClick);

    }, [showCalendar]);

    const handleDropDownClick = (dropDownName: string) => {
        if (selectedFilterDropDown === dropDownName) setSelectedFilterDropDown(null);
        else setSelectedFilterDropDown(dropDownName);
    };

    const handleFilterClick = (key: string, value: Operators | TicketUser | Status | string) => {
        setSelectedTicketFilter((prev) => {
            const currentFilters = prev[key] || [];
            const isSelected = currentFilters.some((filter) => {
                if (typeof filter === 'string' && typeof value === 'string') {
                    return filter === value;
                } else if (typeof filter === 'object' && typeof value === 'object') {
                    if ('assigneeId' in filter && 'assigneeId' in value) {
                        return filter.assigneeId === value.assigneeId;
                    } else if ('id' in filter && 'id' in value) {
                        return filter.id === value.id;
                    }
                }
                return false;
            });

            if (isSelected) {
                return {
                    ...prev,
                    [key]: currentFilters.filter((filter) => {
                        if (typeof filter === 'string' && typeof value === 'string') {
                            return filter !== value;
                        } else if (typeof filter === 'object' && typeof value === 'object') {
                            if ('assigneeId' in filter && 'assigneeId' in value) {
                                return filter.assigneeId !== value.assigneeId;
                            } else if ('id' in filter && 'id' in value) {
                                return filter.id !== value.id;
                            }
                        }
                        return false;
                    }),
                };
            } else {
                return {
                    ...prev,
                    [key]: [...currentFilters, value],
                };
            }
        });
    };

    const handleRemoveFilter = (
        filterToRemove: string | Operators | TicketUser | Status,
        category: string
    ) => {
        setSelectedTicketFilter((prevFilters) => {
            const updatedFilters = { ...prevFilters };

            // Check if the category exists
            if (!updatedFilters[category]) return updatedFilters;

            // Filter out the item to remove
            updatedFilters[category] = updatedFilters[category].filter((item) => {
                if (typeof item === 'string' && typeof filterToRemove === 'string') {
                    return item !== filterToRemove;
                } else if (typeof item === 'object' && typeof filterToRemove === 'object') {
                    // All object filters have 'id' in the sample data
                    return item.id !== (filterToRemove as { id: any }).id;
                }
                return true; // Fallback to keep item if types don't match
            });

            // Remove category if empty
            if (updatedFilters[category].length === 0) {
                delete updatedFilters[category];
            }

            return updatedFilters;
        });
    };

    const handleDateRangeApply = (calendar: any) => {
        setSelectedDateRange({
            startDate: calendar.startDate,
            endDate: calendar.endDate,
            key: calendar.key,
        });
        const value = `${formateDate(calendar.startDate)} - ${formateDate(calendar.endDate)}`;
        const key = 'date';
        setSelectedTicketFilter((prev) => ({ ...prev, [key]: [value] }));
    };

    const handleResetFilter = () => {
        setSelectedTicketFilter({});
        setSelectedDateRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        });
    };

    const handleApplyClick = () => {
        handleFilterApply(selectedTicketFilter);
        onClose();
    };

    const handleAssigneeScroll = () => {
        fetchData(assigneeListresponse.pageNumber + 1)
    }

    const handleAssigneeSearchChange = (value: string) => {
        setAssigneeSearchTerm(value)
    }

    return (
        <div className='control-room-ticket modal right-modal'>
            <div className='border-bottom ticket-modal-header flex justify-start items-center space-x-2'>
                <button onClick={onClose} className='flex'>
                    <img src={arrowsquare} alt='close' />
                </button>
                <span>Filter</span>
            </div>
            <div className={`ticket-modal-content border-bottom ${showCalendar ? 'overflow-visible' : ''}`}>
                <div className='selected-filter-section'>
                    {Object.entries(selectedTicketFilter).map(([category, filters]) =>
                        filters.map((filter, index) => (
                            <div key={`${category}-${index}`} className='selected-filter'>
                                <span>
                                    {typeof filter === 'string'
                                        ? filter
                                        : (filter as TicketUser).name ||
                                        (filter as Operators).name ||
                                        (filter as Status).status}
                                </span>
                                <span
                                    onClick={() => handleRemoveFilter(filter, category)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <img src={Close} alt='remove' />
                                </span>
                            </div>
                        ))
                    )}
                </div>

                <TicketFilterDropDown
                    name='Ticket status'
                    isDropDownOpen={selectedFilterDropDown === 'Ticket status'}
                    handleDropDownClick={handleDropDownClick}
                    options={ticketStatus}
                    selectedFilter={selectedTicketFilter?.['Ticket status']?.filter((filter): filter is Operators | TicketUser | Status => typeof filter !== 'string') || []}
                    handleFilterClick={handleFilterClick}
                    loading={ticketStatusLoading}

                />
                <TicketFilterDropDown
                    name='Assigned to'
                    isDropDownOpen={selectedFilterDropDown === 'Assigned to'}
                    handleDropDownClick={handleDropDownClick}
                    options={assigneeList}
                    selectedFilter={selectedTicketFilter?.['Assigned to']?.filter((filter): filter is Operators | TicketUser | Status => typeof filter !== 'string') || []}
                    handleFilterClick={handleFilterClick}
                    loading={assigneeListLoading}
                    handleAssigneeScroll={handleAssigneeScroll}
                    assigneeSearchValue={assigneeSearchValue}
                    handleAssigneeSearchChange={handleAssigneeSearchChange}
                />
                <div className='well-production-trends__input-container flex ticket-filter-date-picker border-bottom'>
                    <div>Date created</div>
                    <div className='well-production-trends__input-group'>
                        <img src={calendarIcon} alt='Range Calendar' />
                        <input
                            id='range-calendar-input'
                            className='input-date'
                            value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
                            onClick={() => setShowCalendar(!showCalendar)}
                        />
                    </div>

                    <div ref={calendarRef} className='well-production-trends-range ticket-filter-range'>
                        {showCalendar && (
                            <DateRangeSelector
                                setShowCalendar={setShowCalendar}
                                setSelectedDateRange={handleDateRangeApply}
                                staticRanges={customStaticRanges}
                                minDate={defineds?.last180Days}
                                maxDate={defineds?.endOfToday}
                                selectedDateRange={selectedDateRange}
                            />
                        )}
                    </div>
                </div>
                <TicketFilterDropDown
                    name='Operators'
                    isDropDownOpen={selectedFilterDropDown === 'Operators'}
                    handleDropDownClick={handleDropDownClick}
                    options={operators}
                    selectedFilter={selectedTicketFilter?.['Operators']?.filter((filter): filter is Operators | TicketUser | Status => typeof filter !== 'string') || []}
                    handleFilterClick={handleFilterClick}
                    loading={ticketOperatorLoading}
                />
            </div>
            <div className='ticket-modal-footer flex items-center justify-end'>
                <div className='mr-[12px] flex space-x-[8px]'>
                    <button className='cancel-btn' onClick={handleResetFilter}>
                        Reset all
                    </button>
                    <button className='create-btn' onClick={handleApplyClick}>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ControlRoomFilterModal;