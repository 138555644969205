import React, { useLayoutEffect, useRef, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { addDays, endOfDay, startOfDay } from 'date-fns';

interface ProductionChartProps {
  selectedProductionData: { date: string; value: number }[];
}

const ProductionChart: React.FC<ProductionChartProps> = ({
  selectedProductionData,
}) => {
  const [selectedDateRange] = useState({
    startDate: startOfDay(addDays(new Date(), -6)),
    endDate: endOfDay(new Date()),
    key: "selection",
  });

  const chartRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!chartRef.current) return;

    // Convert date strings to timestamps and sort by date
    const transformedData = selectedProductionData
      .map((item) => ({
          date: new Date(item.date).getTime(),
          value: item.value,
        }))
        .sort((a, b) => a.date - b.date);

    const root = am5.Root.new(chartRef.current);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 40,
        paddingRight: 10,
      })
    );

    // X Axis
    const xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.setAll({
      minGridDistance: 80,
      strokeOpacity: 0,
    });
    xRenderer.labels.template.setAll({
      fill: am5.color("#FFFFFF"),
      centerY: am5.p50,
      centerX: am5.p50,
    });

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0,
        min: selectedDateRange.startDate.getTime(),
        max: selectedDateRange.endDate.getTime(),
        baseInterval: { timeUnit: "second", count: 1 },
        renderer: xRenderer,
      })
    );

    // Y Axis
    const yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.setAll({
      strokeOpacity: 0,
    });
    yRenderer.labels.template.setAll({ fill: am5.color("#FFFFFF") });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
      })
    );

    // Create Line Series
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
      })
    );

    series.fills.template.set("fillGradient", am5.LinearGradient.new(root, {
      stops: [{
      opacity: 0.3
      }, {
      opacity: 0
      }],
      rotation: 90
    }));
  
    series.fills.template.setAll({
        visible: true,
        fillOpacity: 0.3
    });

    const cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineX.setAll({ stroke: am5.color("#fff"), strokeOpacity: 0.5 });
    cursor.lineY.setAll({ stroke: am5.color("#fff"), strokeOpacity: 0.5 });
    
    // Ensure tooltips show on hover
    cursor.set("snapToSeries", [series]);
    cursor.set("xAxis", xAxis);

    const tooltip = am5.Tooltip.new(root, {
      labelText: "{valueY}",
      pointerOrientation: "horizontal",
      getFillFromSprite: false,
    });

    tooltip.get("background")?.setAll({
      fill: am5.color(0x0094BD),
      fillOpacity: 0.8,
      stroke: am5.color("#fff"),
    });

    tooltip.label.adapters.add("fill", () => am5.color(0xffffff));

    tooltip.label.setAll({
      fill: am5.color(0xffffff),
      fontSize: 14,
      // fontWeight: "bold",
      oversizedBehavior: "wrap",
      textAlign: "center",
    });

    series.set("tooltip", tooltip);

    // Ensure tooltips are activated on hover
    series.strokes.template.setAll({
      strokeWidth: 2,
      stroke: am5.color("#0094BD"),
      lineJoin: "miter",
    });

    series.set("stroke", am5.color("#0094BD"));

    series.data.setAll(transformedData);

    return () => {
      root.dispose();
    };
  }, [selectedProductionData]);

  return <div ref={chartRef} style={{ width: "100%", height: "350px" }} />;
};

export default ProductionChart;
