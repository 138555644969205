import { AssetDetailsProps } from '../AssetDetailProps';
import '../details-table.scss';
import { ConvertTimeToLocal } from '../../../../../utilities/CommonFunctions';

interface AttributeProps {
  assetDetails?: AssetDetailsProps;
}

const Attributes: React.FC<AttributeProps> = ({ assetDetails }) => {
  return (
    <>
      <div className='details-header-section'>
        <div className='title'>
          <h1>Attributes</h1>
        </div>
        <div className='details-section pb-6'>
          <div className='details-table-row'>
            <div className='details-table-column'>
              <div>Company</div>
              <div>{assetDetails?.company ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>State</div>
              <div>{assetDetails?.state ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>County</div>
              <div>{assetDetails?.county ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Field</div>
              <div>{assetDetails?.field ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Formation</div>
              <div>{assetDetails?.formation ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Basin</div>
              <div>{assetDetails?.basin ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Pad ID</div>
              <div>{assetDetails?.padId ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Pad type</div>
              <div>{assetDetails?.padType ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>API number</div>
              <div>{assetDetails?.apiNumber ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Production start date</div>
              <div>{assetDetails?.productionStartDate ? ConvertTimeToLocal(assetDetails?.productionStartDate) : "-"}</div>
            </div>
            <hr />
   
            <div className='details-table-column'>
              <div>Inferred prod. today (bpd)</div>
              <div>-</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Installation date</div>
              <div>{assetDetails?.installationDate ? ConvertTimeToLocal(assetDetails?.installationDate) : "-"}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Inferred prod. test (bpd)</div>
              <div>-</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Total measurement depth</div>
              <div>{assetDetails?.totalMeasurementDepth ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Incremental oil (bpd)</div>
              <div>-</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Incremental gross (bpd)</div>
              <div>-</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>True vertical depth</div>
              <div>{assetDetails?.trueVerticalDepth ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Well direction</div>
              <div>{assetDetails?.wellDirection ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Longitude</div>
              <div>{assetDetails?.longitude ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Latitude</div>
              <div>{assetDetails?.latitude ?? '-'}</div>
            </div>
            <hr />
            <div className='details-table-column'>
              <div>Custom well ID</div>
              <div>{assetDetails?.otherWellID1 ?? '-'}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Attributes;
